import { useMemo, useState, useEffect } from "react";
import { getCookie } from "../components/utils/cookies";
import { commonData } from "../data/variables/text_words/common.js";
import { companiesWords } from "../../bin/generateSensitiveWord/words/companies";
import useGetSiteMetadata from "./useGetSiteMetadata";
import useGetSiteSvgs from "./useGetSiteSvgs";

import {
  parseTextWithEnviromentVariables,
  getEnviromentVariable,
} from "../components/utils/siteData";

const useSiteData = () => {
  const siteDataOriginal = useGetSiteMetadata();
  const siteInfo = getCookie("site_info");
  const { domain, companyKey, webFulName, hasPhone, termsAndConditionsCompanies } = siteInfo || "";
  const [isLoadingSiteData, setIsLoadingSiteData] = useState(true);
  const memoizedCompaniesIds = useMemo(
    () => termsAndConditionsCompanies,
    [termsAndConditionsCompanies]
  );

  const companyNamesFromId = memoizedCompaniesIds?.map((company) =>
    companiesWords[company].company_name.replace(/ /g, "_")
  );

  const { data: svgSiteData, isLoading: isLoadingSvgSiteData } = useGetSiteSvgs(
    siteDataOriginal.web_fontFamily.replace(/ /g, "-"),
    companiesWords[companyKey]?.company_name.replace(/ /g, "-"),
    siteInfo.domain
  );

  const buildedSiteData = useMemo(() => {
    if (isLoadingSvgSiteData) return;
    if (Object.keys(siteInfo).length === 0) return;

    let baseSiteData = {
      web_name: domain,
      web_full_name: webFulName,
      web_mail: `<a href="mailto:info@${domain}">info@${domain}</a>`,
      ...companiesWords[companyKey],
      ...siteDataOriginal,
      web_price: `$${siteInfo.webPrice}`,
      addon_price_insurance: `$${siteInfo.addonPriceInsurance}`,
      ...commonData,
    };

    if (hasPhone === true) {
      const { companyPhoneNumber } = getCookie("site_info");
      baseSiteData = { ...baseSiteData, site_phone: companyPhoneNumber };
    }

    // ToDo remove PAS-4630 isSiteNegotiatingNewPsp
    const isSiteNegotiatingNewPsp = ["amazingusatravels.com", "leadingtouroffers.com"].includes(
      domain
    );

    // ToDo remove PAS-4630 isSiteNegotiatingNewPsp
    if (!isSiteNegotiatingNewPsp) {
      baseSiteData = { ...baseSiteData, ...svgSiteData };
    }

    return baseSiteData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteInfo]);

  const parsedSiteData = useMemo(() => {
    if (!buildedSiteData) return;
    if (Object.keys(siteInfo).length === 0) return;

    const generateMerchantsList = (companies, textKey) => {
      if (!companies) return;
      return `<ul>${companies
        ?.map(
          (company) =>
            `<li>${
              parseTextWithEnviromentVariables(
                getEnviromentVariable(textKey, buildedSiteData),
                buildedSiteData,
                company
              ) || ""
            }</li>`
        )
        .join("")}</ul>`;
    };

    const parsedSiteData = {
      ...buildedSiteData,
      about_our_services:
        parseTextWithEnviromentVariables(
          getEnviromentVariable("about_our_services", buildedSiteData),
          buildedSiteData
        ) || "",
      about_our_services_es:
        parseTextWithEnviromentVariables(
          getEnviromentVariable("about_our_services_es", buildedSiteData),
          buildedSiteData
        ) || "",
      merchant_of_record_info: generateMerchantsList(companyNamesFromId, "merchant_of_record_info") || "",
      merchant_of_record_info_es: generateMerchantsList(companyNamesFromId, "merchant_of_record_info_es") || "",
      merchant_of_record_info_with_phone:
        parseTextWithEnviromentVariables(
          getEnviromentVariable("merchant_of_record_info_with_phone", buildedSiteData),
          buildedSiteData
        ) || "",
      merchant_of_record_info_with_phone_es:
        parseTextWithEnviromentVariables(
          getEnviromentVariable("merchant_of_record_info_with_phone_es", buildedSiteData),
          buildedSiteData
        ) || "",
    };
    return parsedSiteData;
  }, [buildedSiteData, companyNamesFromId, siteInfo]);

  useEffect(() => {
    if (parsedSiteData) {
      setIsLoadingSiteData(false);
    }
  }, [parsedSiteData]);

  return {
    isLoadingSiteData,
    siteData: parsedSiteData,
  };
};

export default useSiteData;
